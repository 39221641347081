import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main, files } from '../../content/wspolpraca'
import { useLangContext } from '../../context/lang.context'
import { useFormContext } from '../../context/form.context'
import Intro from '../../components/Intro'
import FormSectionCollab from '../../sections/forms/FormSectionCollab'
import Main from '../../components/Main'
import Events from '../../components/Events'

const Wspolpraca = () => {
  const { lang } = useLangContext()
  const { setFormOpen, setFormType } = useFormContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/wspolpraca.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Zainicjuj rozmowę',
      en: 'Initialize conversation',
      ua: 'Почати розмову',
    },
    action: () => {
      setFormType('COLLAB')
      setFormOpen(true)
    },
  }

  const buttonSecond = {
    text: {
      pl: 'Więcej o współpracy',
      en: 'More about collaboration',
      ua: 'Детальніше про співпрацю',
    },

    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
        position='center'
      />
      <Main h={1} title={main.title[lang]}>
        <Events data={files[lang]} />
      </Main>
      <FormSectionCollab h={1} />
    </Layout>
  )
}

export default Wspolpraca
