import React from 'react'
import FormDynamic from '../../components/FormDynamic'
import Main from '../../components/Main'
import { useLangContext } from '../../context/lang.context'

const content = {
  title: {
    pl: 'Rozpocznij współpracę z liderem<br/> spedycji międzynarodowej!',
    en: 'Collaborate with the leader<br/> of an international forwarding!',
    ua: 'Почніть співпрацювати з лідером<br/> міжнародного експедирування!',
  },
  subtitle: {
    pl: 'Formularz kontaktowy',
    en: 'Contact form',
    ua: 'Контактний формуляр',
  },
  desc: {
    pl: 'Jesteś zainteresowany współpracą z nami? Wypełnij formularz kontaktowy w celu uzyskania korzystnej oferty. Odezwiemy się tak szybko, jak tylko to możliwe!',
    en: 'Interested in working with us? Fill out the contact form to obtain an advantageous offer. We will get back to you as soon as possible!',
    ua: 'Ви зацікавлені у співпраці з нами? Заповніть контактну форму для отримання вигідної пропозиції. Ми звяжемося з вами якомога швидше!',
  },
}

const FormSectionCollab = ({ h }) => {
  const { lang } = useLangContext()
  return (
    <Main
      h={h || 2}
      s={1}
      title={content.title[lang]}
      desc={content.desc[lang]}
      subtitle={content.subtitle[lang]}>
      <FormDynamic notypes override='COLLAB' />
    </Main>
  )
}

export default FormSectionCollab
