export const seo = {
  url: 'klient/wspolpraca',
  title: {
    pl: 'Współpraca',
    en: 'Collaboration',
    ua: 'Співпраця',
  },
  desc: {
    pl: `Szukasz doświadczonego partnera logistycznego do realizacji transportu morskiego, lotniczego lub kolejowego? Skontaktuj się z nami!`,
    en: `Looking for an experienced logistics partner for sea, air or rail transport? Contact us!`,
    ua: `Шукаєте досвідченого логістичного партнера для морських, повітряних або залізничних перевезень? Зв'яжіться з нами!`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'współpraca', 'tsl'],
}

export const intro = {
  title: {
    pl: 'Współpraca',
    en: 'Collaboration',
    ua: 'Співпраця',
  },
  desc: {
    pl: `Szukasz doświadczonego partnera logistycznego do realizacji transportu morskiego, lotniczego lub kolejowego? Skontaktuj się z nami!`,
    en: `Looking for an experienced logistics partner for sea, air or rail transport? Contact us!`,
    ua: `Шукаєте досвідченого логістичного партнера для морських, повітряних або залізничних перевезень? Зв'яжіться з нами!`,
  },
}

export const main = {
  title: {
    pl: 'Dlaczego warto współpracować<br/>z Omida Sea And Air',
    en: 'Why it is worth cooperating<br/>with Omida Sea And Air',
    ua: 'Чому варто співпрацювати<br/>з Omida Sea And Air',
  },
}

export const files = {
  pl: [
    {
      title: 'Katalog Omida Sea And Air',
      desc: 'Wersja polska',
      link: '/pdf/PrezentacjaOmidaSeaAndAirPL.pdf',
      targetBlank: true,
    },
    {
      title: 'Katalog Omida Group',
      desc: 'Wersja polska',
      link: 'https://omida.pl/pdf/Omida-Group-Katalog.pdf',
      targetBlank: true,
    },
  ],
  en: [
    {
      title: 'Catalog Omida Sea And Air',
      desc: 'English version',
      link: '/pdf/Presentation_Omida_Sea_And_Air.pdf',
      targetBlank: true,
    },
    {
      title: 'Catalog Omida Group',
      desc: 'English version',
      link: 'https://omida.pl/pdf/Omida-Group-Catalog-ENG.pdf',
      targetBlank: true,
    },
  ],
  ua: [
    {
      title: 'Каталог Omida Sea And Air',
      desc: 'English version',
      link: '/pdf/Presentation_Omida_Sea_And_Air.pdf',
      targetBlank: true,
    },
    {
      title: 'Каталог Omida Group',
      desc: 'English version',
      link: 'https://omida.pl/pdf/Omida-Group-Catalog-ENG.pdf',
      targetBlank: true,
    },
  ],
}
